<template>
    <div class="container mt-5 text-justify">
        <div class="row">
            <div class="col-md-6">
                <img src="../assets/images/rac-protecao-parcial.png" class="img-fluid" />
            </div>
            <div class="col-md-6">
                <img src="../assets/images/rac-protecao-especial.png" class="img-fluid" />
            </div>
            <div class="col-md-6 mt-2">
                <img src="../assets/images/rac-protecao-estendida.png" class="img-fluid" />
            </div>
        </div>
        <!-- <div class="col-sm-12">
            <div v-if="getCarregando" class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>

            <p v-if="!protections.length && !getCarregando" class="text-muted">Nenhum resultado encontrado</p>

            <div v-else v-for="(protection, index) in protections" :key="protection.id">
                <div v-if="protection.titulo === 'Proteção Estendida (ALI)'">
                    <h4 class="text-primary text-center">Terceiros</h4>
                </div>

                <div class="row bg-secondary bg-opacity-10 py-4 mb-2">
                    <div class="col text-center">
                        <h5 class="text-primary text-uppercase">{{ protection.titulo }}</h5>
                    </div>
                    <div class="col-sm-12 col-md-3 text-center" v-for="detail in protection.safeProtection?.protections"
                        :key="protection.id">
                        <span class="badge bg-primary rounded-circle p-2 mx-1 text-uppercase"
                            v-for="category in detail.categories" :key="protection.id">
                            {{ category }}
                        </span>
                        <p class="fw-bold">R$ {{ detail.value }}</p>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>

export default {
    name: 'Proteção',
    components: {},
    methods: {
    },
    computed: {
    },
    mounted() {
        window.scroll({ top: 0 })
    },
    created(){
        document.title = 'King Rent a Car - Proteção'
    },
}
</script>