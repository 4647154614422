import axios from 'axios';


const http = axios.create({
    baseURL: "https://new-backend.kingcar.com.br/api",
});

export default {
    searchBannersCarrousel: () => {
        return http.get('/v1/banners');
    },

    searchTariffsOptional: () => {
        return http.get('/v1/tariffs-optional');
    },

    searchProtections: () => {
        return http.get('/v1/protections');
    },

    searchCompanies: () => {
        return http.get('/v1/companies');
    },

    searchCompanyPhotos: () => {
        return http.get('/v1/company-photos');
    },

    searchRents: () => {
        return http.get('/v1/rents');
    },

    searchCompanyDifferentials: () => {
        return http.get('/v1/company-differentials');
    },

    searchLojas: () => {
        return http.get('/v1/stores');
    },

    searchLojasRetirada: () => {
        return http.get('/v1/stores/availables');
    },

    searchHorariosDisponiveisIdLoja: (idLoja, data) => {
        return http.get(`/v1/stores/${idLoja}/hours-availables?limitedByHour=true&date=${data}`);
    },

    searchGruposCarros: () => {
        return http.get(`/v1/tariffs`);
    },

    searchGruposCarrosRandom: () => {
        return http.get(`/v1/tariffs/random?limit=3`);
    },

    searchGruposCarrosReserva: (dataRetirada, dataDevolucao) => {
        return http.get(`/v1/tariffs/booking-tariff?dataRetira=${dataRetirada}&dataDevolucao=${dataDevolucao}`);
    },

    postLoadInfo: (data) => {
        return http.post('/v1/bookings/load-info', data);
    },

    postLoadInfoCart: (data) => {
        return http.post('/v1/bookings/load-info-cart', data);
    },

    postFinalize: (data) => {
        return http.post('/v1/bookings/finalize', data);
    },

    postContactsSendEmail: (data) => {
        return http.post('/v1/contacts/send-mail', data);
    },
}