import service from "./service";

export const empresa = {
    namespaced: true,
    state: () => ({
        companies: [],
        company: {},
        photos: [],
        banners: []
    }),
    mutations: {
        setCompanies(state, data) {
            state.companies = data;
        },
        setCompany(state, data) {
            state.company = data;
        },
        setPhotos(state, data) {
            state.photos = data;
        },
        setBanners(state, data) {
            state.banners = data;
        },
    },
    actions: {
        async searchCompanyPhotos({ commit }) {
            commit('setCarregando', true, { root: true });
            try {
                const response = await service.searchCompanyPhotos();
                const { items } = await response.data;

                if (items.length > 0) {
                    commit('setPhotos', items)
                }
                commit('setCarregando', false, { root: true });
            } catch (error) {
                commit('setCarregando', false, { root: true });
                commit('setAlerta', "erroPadrao", { root: true });
            }
        },
        async searchBannersCarrousel({ commit }) {
            try {
                const response = await service.searchBannersCarrousel();
                const { items } = await response.data;

                if (items.length > 0) {
                    commit('setBanners', items)
                }
                
            } catch (error) {
                console.log(error);
            }
        },
        async postContactsSendEmail({ commit }, payload) {
            commit('setCarregando', true, { root: true });
            const mensagem = []
            try {
                const response = await service.postContactsSendEmail(payload);
                commit('setCarregando', false, { root: true });
                mensagem.push({
                    titulo: "Obrigada pelo contato!",
                    status: "success",
                    mensagem: response.data.message
                })
                commit('setAlerta', mensagem, { root: true });
            } catch (error) {
                if(Array.isArray(error.response.data.message)){
                    for (const texto of error.response.data.message) {
                        mensagem.push({
                            titulo: "Verifique as informações!",
                            status: "warning",
                            mensagem: texto
                        })
                    }
                    commit('setAlerta', mensagem, { root: true });
                }else{
                    mensagem.push({
                        titulo: "Ops...",
                        status: "warning",
                        mensagem: error.response.data.message
                    })
                    commit('setAlerta', mensagem, { root: true });
                }
                commit('setCarregando', false, { root: true });
            }
        }
    },
    getters: {
        getPhotos(state) {
            return state.photos
        },
        getBanners(state) {
            return state.banners
        },
    }
}