<template>
    <div v-if="getAlerta" aria-live="polite" aria-atomic="true" class="bg-body-secondary position-relative rounded-3">
        <div class="toast-container p-3 start-50 translate-middle-x" id="toastPlacement"
            data-original-class="toast-container p-3" :style="`top: ${scrollY}px`">
            <div v-for="(alerta, index) in getAlerta" :key="index" :class="`text-bg-${alerta.status} toast fade show`">
                <div class="toast-header">
                    <strong class="me-auto">{{ alerta.titulo }}</strong>
                </div>
                <div class="toast-body">
                    {{ alerta.mensagem }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
    computed: {
        ...mapGetters({
            getAlerta: 'getAlerta',
        }),
    },
    data() {
        return {
            scrollY: false
        }
    },
    methods: {
        ...mapMutations({
            setAlerta: 'setAlerta',
        }),
    },
    updated() {
        this.scrollY = window.scrollY;
        if (this.getAlerta !== null) {
            const toastLiveExample = document.getElementById('toastPlacement')
            const toastBootstrap = bootstrap.Toast.getOrCreateInstance(toastLiveExample)
            toastBootstrap.show();
        }
    }
}
</script>
<style scoped>
.toast-header {
    border-bottom: none;
}

strong {
    color: black;
}
</style>