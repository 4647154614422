import router from "@/router";
import service from "./service";
export const grupoCarro = {
    namespaced: true,
    state: () => ({
        gruposCarros: [],
        gruposCarrosRandom: []
    }),
    mutations: {
        setGruposCarros(state, data) {
            const gruposCarros = data.map(grupoCarro => {
                const informacoes = {
                    motor: grupoCarro.motorPortas.split("-")[0],
                    portas: grupoCarro.motorPortas.split("-")[1],
                    pessoas: grupoCarro.pessoas,
                    malas: grupoCarro.malas,
                    ar: grupoCarro.ar,
                    direcao: grupoCarro.direcao,
                    automatico: grupoCarro.automatico,
                    tracao: grupoCarro.tracao,
                    displayInfo: false
                }
                return {
                    id: grupoCarro.id,
                    dataRegistro: grupoCarro.dataRegistro,
                    status: grupoCarro.status,
                    promocao: grupoCarro.promocao,
                    titulo: grupoCarro.titulo,
                    categoria: grupoCarro.categoria,
                    modelo: grupoCarro.modelo,
                    motorista: grupoCarro.motorista,
                    diaria100km: grupoCarro.diaria100km,
                    kmExtra: grupoCarro.kmExtra,
                    diaria5dias: grupoCarro.diaria5dias,
                    diaria6dias: grupoCarro.diaria6dias,
                    imagem: grupoCarro.imagem,
                    urlAmigavel: grupoCarro.urlAmigavel,
                    ordem: grupoCarro.ordem,
                    informacoes
                }
            })
            state.gruposCarros = gruposCarros;
        },
        setGruposCarrosRandom(state, data) {
            const gruposCarros = data.map(grupoCarro => {
                const informacoes = {
                    motor: grupoCarro.motorPortas.split("-")[0],
                    portas: grupoCarro.motorPortas.split("-")[1],
                    pessoas: grupoCarro.pessoas,
                    malas: grupoCarro.malas,
                    ar: grupoCarro.ar,
                    direcao: grupoCarro.direcao,
                    automatico: grupoCarro.automatico,
                    tracao: grupoCarro.tracao,
                    displayInfo: false
                }
                return {
                    id: grupoCarro.id,
                    dataRegistro: grupoCarro.dataRegistro,
                    status: grupoCarro.status,
                    promocao: grupoCarro.promocao,
                    titulo: grupoCarro.titulo,
                    categoria: grupoCarro.categoria,
                    modelo: grupoCarro.modelo,
                    motorista: grupoCarro.motorista,
                    diaria100km: grupoCarro.diaria100km,
                    kmExtra: grupoCarro.kmExtra,
                    diaria5dias: grupoCarro.diaria5dias,
                    diaria6dias: grupoCarro.diaria6dias,
                    imagem: grupoCarro.imagem,
                    urlAmigavel: grupoCarro.urlAmigavel,
                    ordem: grupoCarro.ordem,
                    informacoes
                }
            })
            state.gruposCarrosRandom = gruposCarros;
        },
        exibirMaisDetalhes(state, data) {
            state.gruposCarros[data].informacoes.displayInfo = !state.gruposCarros[data].informacoes.displayInfo;
        }
    },
    actions: {
        async searchGruposCarrosReserva({ state, commit, rootState }) {
            commit('setCarregando', true, { root: true });
            try {
                const dataRetirada = rootState.reserva.formularioReserva.dataRetirada;
                const dataDevolucao = rootState.reserva.formularioReserva.dataDevolucao;
                const response = await service.searchGruposCarrosReserva(dataRetirada,dataDevolucao);
                commit('setGruposCarros', response.data);
                commit('setCarregando', false, { root: true });
            } catch (error) {
                commit('setCarregando', false, { root: true });
                commit('setAlerta', "camposIncompletosPadrao", { root: true });
                router.push({ name: "Home"});
            }
        },
        async searchGruposCarros({ state, commit, rootState }) {
            commit('setCarregando', true, { root: true });
            try {
                const response = await service.searchGruposCarros();
                commit('setGruposCarros', response.data.items);
                commit('setCarregando', false, { root: true });
            } catch (error) {
                commit('setCarregando', false, { root: true });
            }
        },
        async searchGruposCarrosRandom({ state, commit, rootState }) {
            commit('setCarregando', true, { root: true });
            try {
                const response = await service.searchGruposCarrosRandom();
                commit('setGruposCarrosRandom', response.data);
                commit('setCarregando', false, { root: true });
            } catch (error) {
                console.log(1);
                commit('setCarregando', false, { root: true });
                commit('setDisplayError', true, { root: true });
                router.push({name: "Error"})
            }
        },
    },
    getters: {
        getGruposCarros(state, getters, rootState) {
            return state.gruposCarros;
        },
        getGruposCarrosRandom(state, getters, rootState) {
            return state.gruposCarrosRandom;
        },
    }
}