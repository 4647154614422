<template>
    <div id="caixa" class="d-flex justify-content-center align-items-center">
        <div class="text-center">
            <h1 class="m-0 text-primary">
                500
            </h1>
            <small class="">Erro no servidor</small>
        </div>
        <div class="ms-4">
            <h3>Desculpe o transtorno...</h3>
            <p>Estamos com problemas nos nossos servidores, tente novamente mais tarde</p>
            <div class="mt-3">
                <h5>Entre em contato através do nosso whatsapp</h5>
                <a class="btn btn-success" href="https://api.whatsapp.com/send?phone=5527981199997"><i class="bi bi-whatsapp"></i> whatsapp</a>
            </div>
        </div>
    </div>
</template>

<style scoped>
#caixa {
    position: relative;
    height: 100vh;
}

h1 {
    font-size: 5rem;
}
</style>