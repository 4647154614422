import router from "@/router";
import service from "./service";

export const lojas = {
    namespaced: true,
    state: () => ({
        lojasRetirada: [],
        lojas: [],
    }),
    mutations: {
        setLojas(state, data) {
            state.lojas = data;
        },
        setLojasRetirada(state, data) {
            state.lojasRetirada = data.filter(loja => {
                return loja.titulo === "Vitória" || loja.titulo === "Aracruz"
            });
        },
    },
    actions: {
        async searchLojas({ state, commit, rootState }) {
            try {
                const response = await service.searchLojas();
                commit('setLojas', response.data.items);
            } catch (error) {
                commit('setDisplayError', true, { root: true });
                router.push({name: "Error"})
            }
        },

        async searchLojasRetirada({ state, commit, rootState }) {
            try {
                const response = await service.searchLojasRetirada();
                commit('setLojasRetirada', response.data.items);
            } catch (error) {}
        },
    },
    getters: {
        getLojasRetirada(state, getters, rootState) {
            return state.lojasRetirada
        },
        getLojas(state, getters, rootState) {
            return state.lojas
        },
    }
}