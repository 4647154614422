import { createStore } from 'vuex'
import { protecao } from './protecao'
import { empresa } from './empresa'
import { reserva } from './reserva'
import { grupoCarro } from './grupo-carro'
import { lojas } from './lojas'

export default createStore({
    state: {
        displayError: false,
        carregando: false,
        alerta: null//{ titulo: "Ops..", status: "danger", mensagem: "Desculpe ocorreu um erro, tente novamente mais tarde." }
    },
    mutations: {
        setCarregando(state, data) {
            state.carregando = data;
        },
        setDisplayError(state, data) {
            state.displayError = data;
        },
        setAlerta(state, data) {
            // DEFINA MENSAGENS PADRÃO PARA QUE NÃO SEJA NECESSÁRIO ESCREVER VÁRIAS VEZES CADA VEZ QUE CHAMAR O ALERTA
            switch (data) {
                case "erroPadrao":
                    state.alerta = [{ titulo: "Ops..", status: "danger", mensagem: "Desculpe ocorreu um erro, tente novamente mais tarde." }]
                    break;
                case "erroPadraoReserva":
                    state.alerta = [{ titulo: "Ops..", status: "danger", mensagem: "Desculpe ocorreu um erro ao solicitar sua reserva, tente novamente mais tarde." }]
                    break;
                case "camposIncompletosPadrao":
                    state.alerta = [{ titulo: "Ops..", status: "warning", mensagem: "Por favor preencha o formulário para seguir com a reserva." }]
                    break;
                case "sucessoPadrao":
                    state.alerta = [{ titulo: "Obrigada por utilizar nossos serviços!", status: "success", mensagem: "Sua solicitação de reserva foi encaminhada para nossa unidade, aguardamos sua presença." }]
                    break;
                case "naoSouRoboObrigatorio":
                    state.alerta = [{ titulo: "Ops..", status: "warning", mensagem: "Por favor resolva o captcha." }]
                    break;

                default:
                    //CASO QUEIRA PASSAR O STATUS E A MENSAGEM MANUALMENTE
                    state.alerta = data
                    break;
            }
        },
    },
    getters: {
        getCarregando(state, getters, rootState) {
            return state.carregando
        },
        getAlerta(state, getters, rootState) {
            return state.alerta
        },
    },
    modules: {
        protecao,
        empresa,
        reserva,
        grupoCarro,
        lojas,
    }
})
